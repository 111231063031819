import Phaser from 'phaser';
const DEFAULT_BASE_URL = 'http://localhost:3000';

const SCREEN_WIDTH = 720;
const SCREEN_HEIGHT = 512;

const GRAVITY = 300.0;
const BIRD_SCALE = 0.2;
const BIRD_FRAME_SIZE = 300;
const GROUND_WIDTH = 336.0;
const GROUND_HEIGHT = 112.0;
const BACKGROUND_WIDTH = 910.0;
const BACKGROUND_HEIGHT = 512.0;
const SCENERY_SPEED = 100.0;
const PIPE_WIDTH = 52.0;
const STARTING_ANGLE = -15.0;
const ANGLE_TURN_SPEED = 90.0;
const FLAP_DURATION = 0.08;
const PIPE_SPAWN_TIME = 2.0;
const PIPE_RANDOM_Y = 120.0;
const SCORE_FONT_SIZE = 36.0;
const BIRD_B_BOX_Y = 120;
const BIRD_B_BOX_X = 150;

const EVENT_GAME_STARTED = 'gameStarted';
const EVENT_GAME_OVER = 'gameOver';
const EVENT_POINT = 'point';
const EVENT_FLAP = 'flap';

let globalBirdFrames = null;
let globalBaseURL = null;
let globalEventCallback = null;

class GameScene extends Phaser.Scene {
  init() {
    this.loading = true;
    this.score = 0;

    this.upFrames = 0;

    this.gameStarted = false;
    this.isNight = false;
    this.gameOver = false;
    this.falling = false;

    this.lastFrameTs = 0;
    this.framesCounter = 0;
    this.fps = 0;

    this.flapTimer = 0.0;

    this.pipeTimer = PIPE_SPAWN_TIME;
  }

  emitEvent(event) {
    if (globalEventCallback) {
      globalEventCallback(event);
    }
  }

  loadNFTFrames(onDone) {
    let image = new Image();
    image.onload = () => {
      this.textures.addSpriteSheet('bird', image, {
        frameWidth: BIRD_FRAME_SIZE,
        frameHeight: BIRD_FRAME_SIZE,
      });
      onDone();
      globalBirdFrames = null;
    };
    image.src = globalBirdFrames;
  }

  loadGame() {
    this.background = this.add
      .tileSprite(0, 0, BACKGROUND_WIDTH, BACKGROUND_HEIGHT, 'backgroundDay')
      .setOrigin(0)
      .setDepth(0);

    this.bird = this.physics.add
      .sprite(0, 0, 'bird', 0)
      .setScale(BIRD_SCALE)
      .setSize(BIRD_B_BOX_X, BIRD_B_BOX_Y)
      // .setCollideWorldBounds(true)
      .setDepth(30);

    this.anims.create({
      key: 'flap',
      frames: this.anims.generateFrameNumbers('bird', {
        start: 0,
        end: 2,
      }),
      frameRate: 10,
      repeat: -1,
    });

    const groundScale = SCREEN_WIDTH / GROUND_WIDTH;

    this.ground = this.physics.add
      .sprite(0, SCREEN_HEIGHT, 'ground')
      .setScale(groundScale, 1)
      .setCollideWorldBounds(true)
      .setDepth(20);

    this.anims.create({
      key: 'groundMoving',
      frames: this.anims.generateFrameNumbers('ground', {
        start: 0,
        end: 2,
      }),
      frameRate: 10,
      repeat: -1,
    });

    this.hitSound = this.sound.add('hit');
    this.hitSound.on('complete', () => {
      this.bird.setVelocityY(GRAVITY);

      this.sound.play('die');
    });

    this.pipesGroup = this.physics.add.group();
    this.gapsGroup = this.physics.add.group();
    this.boundariesGroup = this.physics.add.group();

    const topLine = this.add
      .line(0, 0, 0, 0, SCREEN_WIDTH, 0)
      .setOrigin(0)
      .setVisible(false);
    this.boundariesGroup.add(topLine);
    topLine.body.allowGravity = false;

    this.physics.add.overlap(
      this.bird,
      this.boundariesGroup,
      this.onHit,
      null,
      this
    );
    this.physics.add.overlap(
      this.bird,
      this.gapsGroup,
      this.onPoint,
      null,
      this
    );
    this.physics.add.overlap(
      this.bird,
      this.pipesGroup,
      this.onHit,
      null,
      this
    );
    this.physics.add.overlap(this.bird, this.ground, this.onHit, null, this);

    this.scoreText = this.add
      .text(20, 20, this.score, {
        fontSize: SCORE_FONT_SIZE,
        fontFamily: 'f04b',
        resolution: 20,
        shadow: { offsetX: 50, offsetY: 50, fill: true },
      })
      .setDepth(50);

    this.gameOverText = this.add
      .text(SCREEN_WIDTH / 2, SCREEN_HEIGHT / 2.6, 'Game Over', {
        fontSize: 48,
        fontFamily: 'f04b',
        resolution: 20,
        shadow: { offsetX: 50, offsetY: 50, fill: true },
      })
      .setOrigin(0.5, 0.5)
      .setVisible(false)
      .setDepth(100);

    this.restartButton = this.add
      .image(SCREEN_WIDTH / 2, SCREEN_HEIGHT * 0.6, 'restartButton')
      .setDepth(100)
      .setVisible(false)
      .setInteractive({ useHandCursor: true })
      .on('pointerdown', () => this.startGame());

    this.flapKey = this.input.keyboard.addKey(
      Phaser.Input.Keyboard.KeyCodes.SPACE
    );

    this.restartKey = this.input.keyboard.addKey(
      Phaser.Input.Keyboard.KeyCodes.R
    );

    this.input.on('pointerdown', () => this.flap());

    this.loading = false;
  }

  preload() {
    this.load.setBaseURL(globalBaseURL);

    this.load.spritesheet('ground', 'assets/ground-placeholder.png', {
      frameWidth: GROUND_WIDTH,
      frameHeight: GROUND_HEIGHT,
    });

    this.load.image('backgroundDay', 'assets/background-day-placeholder.png');
    this.load.image(
      'backgroundNight',
      'assets/background-night-placeholder.png'
    );
    this.load.image('pipe', 'assets/pipe-placeholder.png');
    this.load.image('restartButton', 'assets/restart-button-placeholder.png');

    // SFX
    this.load.audio('flap', 'assets/sfx/flap.mp3');
    this.load.audio('point', 'assets/sfx/point.mp3');
    this.load.audio('hit', 'assets/sfx/hit.mp3');
    this.load.audio('die', 'assets/sfx/die.mp3');
  }

  create() {
    this.loadNFTFrames(() => {
      this.loadGame();
      this.startGame();
    });
  }

  update(time, delta) {
    if (this.loading) {
      return;
    }

    const deltaSeconds = delta * 0.001;

    if (this.gameOver) {
      if (Phaser.Input.Keyboard.JustDown(this.restartKey)) {
        this.startGame();
      }
      return;
    } else if (this.falling) {
      if (this.bird.y > SCREEN_HEIGHT * 1.1) {
        this.stopGame();
      }
      return;
    }

    // Scroll background
    this.background.tilePositionX += SCENERY_SPEED * deltaSeconds;

    if (this.flapTimer > 0) {
      this.flapTimer -= deltaSeconds;
    } else if (Phaser.Input.Keyboard.JustDown(this.flapKey)) {
      this.flap(deltaSeconds);
    } else {
      this.fall(deltaSeconds);
    }

    this.pipesGroup.children.iterate((child) => {
      if (child) {
        if (child.x < -PIPE_WIDTH) {
          child.destroy();
        } else {
          child.setVelocityX(-SCENERY_SPEED);
        }
      }
    });

    this.gapsGroup.children.iterate((child) => {
      child.body.setVelocityX(-SCENERY_SPEED);
    });

    this.pipeTimer -= deltaSeconds;
    if (this.pipeTimer < 0) {
      this.spawnPipe();
    }
  }

  onHit() {
    if (this.loading || this.falling || this.gameOver) {
      return;
    }

    this.hitSound.play();

    this.falling = true;
    this.bird.setVelocityY(GRAVITY * -1.1);
  }

  onPoint(_, gap) {
    if (this.loading || this.gameOver || this.falling) {
      return;
    }
    this.incrementPoints(gap);
  }

  startGame() {
    this.gameOver = false;
    this.falling = false;
    this.isNight = false;

    this.flapTimer = 0;
    this.pipeTimer = PIPE_SPAWN_TIME;

    this.bird.setPosition(60, 265);
    this.bird.angle = STARTING_ANGLE;
    this.bird.play('flap');
    this.ground.play('groundMoving');
    this.background.setTexture('backgroundDay');

    this.background.tilePositionX = 0;

    this.pipesGroup.clear(true, true);
    this.gapsGroup.clear(true, true);

    this.restartButton.setVisible(false);
    this.gameOverText.setVisible(false);

    this.score = 0;
    this.scoreText.setText('0');

    this.physics.resume();

    this.emitEvent(EVENT_GAME_STARTED);
  }

  stopGame() {
    this.gameOver = true;

    // Stop animations and physics
    this.bird.stop();
    this.ground.stop();

    this.physics.pause();

    this.gameOverText.setVisible(true);
    this.restartButton.setVisible(true);

    // TODO: Save highest score

    this.emitEvent(EVENT_GAME_OVER);
  }

  flap() {
    if (this.loading || this.gameOver || this.falling) {
      return;
    }

    this.bird.setVelocityY(GRAVITY * -1.1);
    this.bird.angle = STARTING_ANGLE;

    this.flapTimer = FLAP_DURATION;

    this.sound.play('flap');

    this.emitEvent(EVENT_FLAP);
  }

  fall(deltaSeconds) {
    this.bird.setVelocityY(GRAVITY * 0.4);

    if (this.bird.angle < 90) {
      this.bird.angle += deltaSeconds * ANGLE_TURN_SPEED;
    }
  }

  spawnPipe() {
    const topPipeY = Phaser.Math.Between(-PIPE_RANDOM_Y, PIPE_RANDOM_Y);

    const gapLine = this.add
      .line(SCREEN_WIDTH, topPipeY + 210, 0, 0, 0, 98)
      .setVisible(false);
    this.gapsGroup.add(gapLine);
    gapLine.body.allowGravity = false;

    const topPipe = this.pipesGroup.create(SCREEN_WIDTH, topPipeY, 'pipe');
    topPipe.setFlipY(true);
    topPipe.body.allowGravity = false;

    const bottomPipe = this.pipesGroup.create(
      SCREEN_WIDTH,
      topPipeY + 420,
      'pipe'
    );
    bottomPipe.body.allowGravity = false;

    // Reset timer
    this.pipeTimer = PIPE_SPAWN_TIME;
  }

  incrementPoints(gap) {
    this.score++;
    this.scoreText.setText(`${this.score}`);

    // Toggle background image
    if (this.score % 10 === 0) {
      this.isNight = !this.isNight;

      this.background.setTexture(
        this.isNight ? 'backgroundNight' : 'backgroundDay'
      );
    }

    gap.destroy();

    this.sound.play('point');

    this.emitEvent(EVENT_POINT);
  }
}

const defaultGameConfig = {
  type: Phaser.AUTO,
  width: SCREEN_WIDTH,
  height: SCREEN_HEIGHT,
  pixelArt: true,
  physics: {
    default: 'arcade',
    arcade: {
      debug: false,
      gravity: {
        y: GRAVITY,
      },
    },
  },
  scene: [GameScene],
  inputKeyboard: true,
  inputTouch: true,
  inputMouse: true,
  hideBanner: true,
  hidePhaser: true,
  clearBeforeRender: false,
  failIfMajorPerformanceCaveat: true,
  autoFocus: true,
};

/**
 * This function must be called to create the game instance.
 *
 * @param {*} canvasId Id of the canvas element to use. If not specified
 * Phaser will create a new one.
 * @param {*} baseURL the website base URL
 * @param {*} birdFrames bird frames, fetched from NFTs
 * @param {*} customConfig an optional Phaser config object which can be used
 * to extend default configuration
 */
export function createGame(
  canvasId,
  baseURL,
  birdFrames,
  eventCallback,
  customConfig
) {
  let configToUse = defaultGameConfig;

  if (canvasId) {
    const gameCanvas = document.getElementById(canvasId);

    configToUse = { ...configToUse, type: Phaser.CANVAS, canvas: gameCanvas };
  }

  if (customConfig) {
    configToUse = { ...configToUse, ...customConfig };
  }

  globalBirdFrames = birdFrames;
  globalBaseURL = baseURL || DEFAULT_BASE_URL;
  globalEventCallback = eventCallback;

  window.game = new Phaser.Game(configToUse);
}

export function changeBird(birdFrames) {
  console.log(birdFrames);
  globalBirdFrames = birdFrames;
}
