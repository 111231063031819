import React, { useEffect, useState } from 'react';
import {
  Container,
  Image,
  Modal,
  Spinner,
  Toast,
  ToastContainer,
} from 'react-bootstrap';
import { changeBird, createGame } from './utils/game';
import { ethers } from 'ethers';
import contract from './contracts/BirdsNFT.json';

// const NETWORK_ID = 80001;
const NETWORK_ID = 137;

// const contractAddress = '0x9848096B185D6fB468841154cCAB9fb91905cA7a';
const contractAddress = '0xD45afbcdE45f3D638097106350475F1227a606b9';
const abi = contract.abi;

declare global {
  interface Window {
    ethereum: {
      request<T>(params: { method: string }): Promise<T>;
      on<T>(event: string, cb: (params: T) => void): void;
      removeListener<T>(event: string, cb: (params: T) => void): void;
      selectedAddress: string | undefined;
    };
  }
}

interface Metadata {
  description: string;
  image: string;
  name: string;
  animation: string;
  attributes: Array<{
    display_type?: string;
    trait_type?: string;
    value?: number;
  }>;
  external_link: string;
  seller_fee_basis_points: number;
  fee_recipient: string;
}

const App: React.FC = () => {
  const blobToBase64 = (blob: Blob) => {
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    return new Promise((resolve) => {
      reader.onloadend = () => {
        resolve(reader.result);
      };
    });
  };

  const changeCharacter = (selected: Metadata) => {
    fetch(ipfsLinkBuilder(selected.animation))
      .then((res) => {
        return res.blob();
      })
      .then((blob: Blob) => {
        blobToBase64(blob).then((res) => {
          setLoading(false);
          setShow(false);
          setGameStarted(true);
          changeBird(res);
        });
      });
  };

  const startGame = (selected: Metadata) => {
    fetch(ipfsLinkBuilder(selected.animation))
      .then((res) => {
        return res.blob();
      })
      .then((blob: Blob) => {
        blobToBase64(blob).then((res) => {
          setLoading(false);
          setShow(false);
          const eventCb = (event: any) => console.log(`Game event: ${event}`);
          setGameStarted(true);
          createGame('gameCanvas', window.location.href, res, eventCb, {});
        });
      });
  };

  const [currentAccount, setCurrentAccount] = useState('');
  const [owned, setOwned] = useState<Array<Metadata>>([]);
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [price, setPrice] = useState('');
  const [error, setError] = useState('');
  const [showToast, setShowToast] = useState(false);
  const [gameStarted, setGameStarted] = useState(false);
  const [focus, setFocus] = useState('assets/button.svg');
  const [metamaskFocus, setMetamaskFocus] = useState(
    'assets/connect-metamask.svg'
  );
  const [mintFocus, setMintFocus] = useState('assets/mint-now.svg');

  useEffect(() => {
    const call = async () => {
      try {
        const { ethereum } = window;

        if (ethereum) {
          const provider = new ethers.providers.Web3Provider(ethereum);
          const signer = provider.getSigner();
          const nftContract = new ethers.Contract(contractAddress, abi, signer);

          const tmp = await nftContract.getPrice();
          setPrice((Number(tmp.toString()) / Math.pow(10, 18)).toString());
        } else {
          console.log('Ethereum object does not exist');
        }
      } catch (err) {
        console.log(err);
      }
    };
    call();
  }, []);

  const ipfsLinkBuilder = (str: string) => {
    return `https://cloudflare-ipfs.com/ipfs/${str.split('://')[1]}`;
  };

  const connectWalletHandler = async () => {
    const { ethereum } = window;

    if (!ethereum) {
      setError('Please install Metamask!');
      setShowToast(true);
    }

    try {
      const accounts: string[] = await ethereum.request({
        method: 'eth_requestAccounts',
      });
      setCurrentAccount(accounts[0]);
      const chainID: any = await ethereum.request({ method: 'eth_chainId' });
      //if(chainID != 137){
      if (parseInt(chainID, 16) !== NETWORK_ID) {
        setError(
          `Please connect to Polygon Mainnet, wrong network detected (ID ${chainID})`
        );
        setShowToast(true);
        setCurrentAccount('');
      } else {
        const tmp = await getUserNFTs(accounts[0]);
        setOwned(tmp !== undefined ? tmp : []);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const getUserNFTs = async (account: string) => {
    try {
      const { ethereum } = window;

      if (ethereum) {
        const provider = new ethers.providers.Web3Provider(ethereum);
        const signer = provider.getSigner();
        const nftContract = new ethers.Contract(contractAddress, abi, signer);
        const ids = await nftContract.getOwnershipHistory(account);
        const owned = [];
        for (let i of ids) {
          const owner = await nftContract.ownerOf(i);
          if (owner === ethers.utils.getAddress(account)) {
            owned.push(i.toNumber());
          }
        }

        console.table(owned);

        const items: Array<Metadata> = [];
        for (var o of owned) {
          nftContract.tokenURI(o).then((uri: any) => {
            fetch(ipfsLinkBuilder(uri))
              .then((response) => response.json())
              .then((data) => items.push(data));
          });
        }
        console.table(items);
        return items;
      } else {
        console.log('Ethereum object does not exist');
      }
    } catch (err) {
      console.log(err);
    }
  };

  const mintNftHandler = async () => {
    try {
      const { ethereum } = window;

      if (ethereum) {
        const provider = new ethers.providers.Web3Provider(ethereum);
        const signer = provider.getSigner();
        const nftContract = new ethers.Contract(contractAddress, abi, signer);

        console.log('Initialize payment');
        const price = await nftContract.getPrice();
        nftContract
          .mint({ value: price })
          .then((nftTxn: any) => {
            console.log('Mining... please wait');
            nftTxn
              .wait()
              .then(async () => {
                if (currentAccount) {
                  await getUserNFTs(currentAccount);
                }
              })
              .catch(() => {
                setError(
                  `Seems like we were not able to Mint your NFT. Please check your wallet balance and retry!`
                );
                setShowToast(true);
              });
          })
          .catch(() => {
            setError(
              `Seems like we were not able to Mint your NFT. Please check your wallet balance and retry!`
            );
            setShowToast(true);
          });

        // console.log(`Mined, see transaction: https://rinkeby.etherscan.io/tx/${nftTxn.hash}`);
      } else {
        console.log('Ethereum object does not exist');
      }
    } catch (err) {
      console.log(err);
    }
  };

  const play = () => {
    if (owned.length === 0) {
      setError(
        `Sorry my friend, you don't have a bird to play with. Please mint one and have fun!`
      );
      setShowToast(true);
    } else {
      setShow(true);
    }
  };

  const date = new Date();

  const hour = date.getHours();

  return (
    <div
      className="App"
      style={{
        width: '100vw',
        height: '100vh ',
        backgroundColor:
          hour > 6 && hour < 19 ? 'rgb(35, 174, 255)' : 'rgb(28, 53, 67)',
        backgroundImage:
          hour > 6 && hour < 19
            ? 'url(assets/background-day-placeholder.png)'
            : 'url(assets/background-night-placeholder.png)',
        objectFit: 'cover',
        backgroundRepeat: 'repeat-x',
        backgroundPosition: 'bottom',
        backgroundSize: '100% 100%',
        animation: 'ani 10s linear infinite',
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          padding: '20px 100px',
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-start',
            alignItems: 'center',
          }}
        >
          <Image
            style={{
              maxHeight: '50px',
              cursor: 'pointer',
            }}
            onClick={() => {
              window.open(
                'https://opensea.io/collection/flappy-nft-birds',
                '_blank'
              );
            }}
            src="assets/opensea.svg"
          />
          <Image
            style={{
              maxHeight: '50px',
              cursor: 'pointer',
              marginLeft: '20px',
            }}
            onClick={() => {
              window.open('https://discord.gg/J5ykD6rvWr', '_blank');
            }}
            src="assets/discord.png"
          />
          <span
            style={{
              color: 'white',
              fontFamily: 'f04b',
              marginLeft: '20px',
            }}
          >
            Just {price} MATIC
          </span>
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-end',
            alignItems: 'center',
          }}
        >
          <span style={{ color: 'white', marginRight: '30px' }}>
            {currentAccount ? currentAccount : 'Connect to Polygon network!'}
          </span>

          {currentAccount === '' ? (
            <img
              src={metamaskFocus}
              onClick={() => connectWalletHandler()}
              onFocus={() =>
                setMetamaskFocus('assets/connect-metamask-focus.svg')
              }
              onBlur={() => setMetamaskFocus('assets/connect-metamask.svg')}
              onMouseDown={() =>
                setMetamaskFocus('assets/connect-metamask-focus.svg')
              }
              onMouseUp={() => setMetamaskFocus('assets/connect-metamask.svg')}
            />
          ) : (
            <img
              src={mintFocus}
              onClick={() => mintNftHandler()}
              onFocus={() => setMintFocus('assets/mint-now-focus.svg')}
              onBlur={() => setMintFocus('assets/mint-now.svg')}
              onMouseDown={() => setMintFocus('assets/mint-now-focus.svg')}
              onMouseUp={() => setMintFocus('assets/mint-now.svg')}
            />
          )}
        </div>
      </div>
      <ToastContainer
        position="top-end"
        style={{
          marginTop: '100px',
          marginRight: '120px',
        }}
      >
        <Toast
          show={showToast}
          onClose={() => setShowToast(false)}
          style={{ backgroundColor: '#1f2124' }}
        >
          <Toast.Header
            style={{
              justifyContent: 'flex-end',
              backgroundColor: '#1f2124',
              margin: '10px',
              border: '0',
            }}
            closeVariant="white"
          ></Toast.Header>
          <Toast.Body
            style={{
              padding: '0px 30px 30px 30px',
              backgroundColor: '#1f2124',
            }}
          >
            <span style={{ color: 'white', fontFamily: 'f04b' }}>{error}</span>
          </Toast.Body>
        </Toast>
      </ToastContainer>
      <Container
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'center',
          height: '80vh',
        }}
        fluid
        id="container"
      >
        {!gameStarted ? (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'space-around',
              height: '100%',
            }}
          >
            <span
              style={{
                fontSize: '64pt',
                fontFamily: 'f04b',
                color: 'white',
              }}
            >
              Flappy NFT Birds
            </span>
            <img
              src="assets/birds/default-bird.gif"
              style={{
                width: '15vw',
                animation: 'mover 2s infinite  alternate',
              }}
            />

            <img
              src={focus}
              onClick={() => play()}
              onFocus={() => setFocus('assets/button-pressed.svg')}
              onBlur={() => setFocus('assets/button.svg')}
              onMouseDown={() => setFocus('assets/button-pressed.svg')}
              onMouseUp={() => setFocus('assets/button.svg')}
            />
          </div>
        ) : (
          <canvas
            id="gameCanvas"
            style={{
              height: '80vh',
              marginTop: '2vh',
              borderRadius: '40px',
              border: '20px solid white',
              borderStyle: 'double',
            }}
          ></canvas>
        )}
        <Modal
          show={show}
          onHide={() => setShow(false)}
          size={'lg'}
          centered
          scrollable
        >
          <Modal.Header
            closeButton
            closeVariant="white"
            style={{
              backgroundColor: '#1f2124',
              border: '0',
            }}
          ></Modal.Header>
          {loading ? (
            <Modal.Body
              style={{
                flexDirection: 'row',
                display: 'flex',
                alignContent: 'center',
                justifyContent: 'space-between',
                flexWrap: 'wrap',
                backgroundColor: '#1f2124',
              }}
            >
              <Spinner animation="border" variant="success" />
            </Modal.Body>
          ) : (
            <Modal.Body
              style={{
                flexDirection: 'row',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-start',
                flexWrap: 'wrap',
                backgroundColor: '#1f2124',
              }}
            >
              {owned.map((own) => (
                <Image
                  style={{
                    cursor: 'pointer',
                    width: '150px',
                    height: '150px',
                    margin: '15px',
                    borderRadius: '20px',
                  }}
                  onClick={() => {
                    setLoading(true);
                    if (gameStarted) {
                      changeCharacter(own);
                    } else {
                      startGame(own);
                    }
                  }}
                  key={own.name}
                  src={ipfsLinkBuilder(own.image)}
                />
              ))}
            </Modal.Body>
          )}
        </Modal>
      </Container>
    </div>
  );
};

export default App;
